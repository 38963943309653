import { defineStore } from "pinia";

export const useMatchHistory = defineStore("matchHistory", () => {
  const dict = useDictionaries();
  const { user } = storeToRefs(useUser());

  const page = ref(1);
  const dateAfter = ref();
  const dateBefore = ref();
  const game = ref(useMap(user.value?.games, "id"));
  const game2query = computed(() => useJoin(game.value, ","));
  watch([game, dateAfter, dateBefore], () => (page.value = 1));

  const matches = ref([]);
  const {
    data: parties,
    status,
    refresh,
  } = useAPI("/parties/v2/", {
    transform: (source) => {
      return {
        results: enrichOpportunity(enrich(source.results, dict.dictionaries)),
        next: source.next,
        count: source.count,
      };
    },
    query: {
      mode: "completed",
      game: game2query,
      date_before: dateBefore,
      date_after: dateAfter,
      page,
    },
    watch: [game, dateBefore, dateAfter],
  });

  const matchesCount = computed(() => parties.value?.count);
  const next = computed(() => !!parties.value?.next);

  watch(parties, (value) => {
    if (page.value === 1) {
      matches.value = value.results;
    } else {
      matches.value.push(...value.results);
    }
  });

  return {
    matches,
    matchesCount,
    page,
    dateAfter,
    dateBefore,
    game,
    next,
    status,
    refresh,
  };
});
